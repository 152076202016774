import * as React from 'react';
import {Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
// import styled from "styled-components"
import styled from '@emotion/styled';
import Layout from '../components/layout';
import SiteWidthWrapper from '../components/wrappers/siteWidthWrapper';
import Seo from '../components/seo';
import HeroImageA from '../components/MainHero';
import StyledButton from '../components/button';

const ServicesWrapperOuter = styled.section`
  display: flex;
  justify-content: center;

  width: 100%;

  margin: 64px auto 32px;
`;

const ServicesWrapperInner = styled.div`
  .HomeBoxA, .HomeBoxB, .HomeBoxC {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      margin-bottom: 10px;
    }

    img {
      margin: 0;
      padding: 0;
    }

    a {
      margin: 16px 0 0 0;
      padding: 0;

      text-decoration: none;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1.6px;
    }
  }

  .HomeBoxA {
    grid-area: BoxA;
  }

  .HomeBoxB {
    grid-area: BoxB;
  }

  .HomeBoxC {
    grid-area: BoxC;
  }

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "BoxA BoxB BoxC";
    gap: 16px;
  }
`;

const StyledHR = styled.hr`
  width: 80%;
  height: 8px;
  background: var(--gold);
  border: none;

  margin: 64px auto;
`;

const IndexPage = () => (

    <Layout>
    <Seo title='Top1 Painting' description="Painting &amp; Reno's" />

      {/* Hero Image is found in /components/MainHero.js */}
      <HeroImageA />

    <SiteWidthWrapper>
      <h1>Interior &amp; Exterior Painting Services</h1>
      <p>We always Maintain the highest level of honesty, integrity, and fairness in our relationships with our customers and employees at all times.</p>
      <p>Top1 Painting &amp; Reno provides exceptional Interior and Exterior painting services for residential and commercial clients. Fully licensed & insured, with over 13 years experience! We welcome the opportunity to discuss any painting project leaving you confident in our ability to deliver great customer service and out-standing craftsmanship every time.</p>
      
      <ServicesWrapperOuter>
        <ServicesWrapperInner>
          <div className='HomeBoxA'>
            <h2>Interior Painting</h2>
            <StaticImage
                src='../images/home/interior-painting.jpg'
                width={360}
                height={360}
                quality={95}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt='interior painting services'
                loading='lazy'
            />
            <Link to='/services/interior-painting'
                  title='interior painting services'>
              <StyledButton>Interior Painting</StyledButton>
            </Link>
          </div>
          <div className='HomeBoxB'>
            <h2>Exterior Painting</h2>
            <StaticImage
                src='../images/home/exterior-painting.jpg'
                width={360}
                height={360}
                quality={95}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt='exterior painting services'
                loading='lazy'
            />
            <Link to='/services/exterior-painting'
                  title='exterior painting services'>
              <StyledButton>Exterior Painting</StyledButton>
            </Link>
          </div>
          <div className='HomeBoxC'>
            <h2>Pressure Washing</h2>
            <StaticImage
                src='../images/home/pressure-washing.jpg'
                width={360}
                height={360}
                quality={95}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt='pressure washing services'
                loading='lazy'
            />
            <Link to='/services/pressure-washing'
                  title='pressure washing services'>
              <StyledButton>Pressure Washing</StyledButton>
            </Link>
          </div>
        </ServicesWrapperInner>
      </ServicesWrapperOuter>

    <p>We pride ourselves in the relationship we build with our customers, through ongoing communication, trust and respect.’ At Top1 Painting we strive in providing the highest level of quality painting services at fair prices, developing long term relationships with our customers through repeat and referral business achieved through customer satisfaction at each stage of any painting project by focusing on attention to detail, timeliness, respect, cleanliness and customer appreciation.</p>

    <StyledHR />

    <h2>Our Service Area</h2>
    <p style={{textAlign: `center`}}>We work throughout Vancouver and surrounding cities including but not limited to: Coquitlam, Burnaby,New Westminster, Port Coquitlam, Pitt Meadows, Maple Ridge, Port Moody, Surrey, Langley</p>

    </SiteWidthWrapper>
  </Layout>
);

export default IndexPage;
